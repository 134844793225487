<template>
  <div>
    <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="85px" label-position="top" style="padding: 0 20px">
      <div class="title">
        <div class="line"></div>
        <div class="text">基础信息</div>
      </div>
      <el-row :gutter="64">
        <el-col :span="12">
          <el-form-item label="企业名称" prop="nameOfEnterprise">
            <el-input v-model="saveData.nameOfEnterprise" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属行业" prop="industry">
            <!-- <el-input v-model="saveData.industry" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input> -->
            <el-select v-model="saveData.industry">
               <el-option v-for="(item,index) in industryList" :key="index" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册资金（万元）" prop="registeredCapital">
            <el-input type="number" v-model="saveData.registeredCapital" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法定代表人" prop="legalPerson">
            <el-input v-model="saveData.legalPerson" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目联系人" prop="projectContact">
            <el-input v-model="saveData.projectContact" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式" prop="contactWay">
            <el-input v-model="saveData.contactWay" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建筑面积（亩）" prop="builtUpArea">
            <el-input type="number" v-model.number="saveData.builtUpArea" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="占地面积:">
            <!-- <el-input type="number" v-model.number="saveData.areaCovered" placeholder="请输入" :maxlength="20"></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="自有（亩）" prop="have">
            <el-input type="number" v-model.number="saveData.have" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="租赁（亩）" prop="lease">
            <el-input type="number" v-model.number="saveData.lease" placeholder="请输入" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合计（亩）">
            <el-input type="number" v-model.number="total1" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="现有员工人数:">
            <!-- <el-input type="number" v-model="saveData.existEmployeesNum" placeholder="请输入" :maxlength="20"></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="管理人员人数(人)" prop="managersNum">
            <el-input-plus type="integer" v-model="saveData.managersNum"  placeholder="请输入" :disabled="disabled"></el-input-plus>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="研发技术人员人数(人)" prop="artisanNum">
            <el-input-plus type="integer" v-model="saveData.artisanNum"  placeholder="请输入" :disabled="disabled"></el-input-plus>

          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="生产人员人数(人)" prop="productionPersonnelNum">
            <el-input-plus type="integer" v-model="saveData.productionPersonnelNum"  placeholder="请输入" :disabled="disabled"></el-input-plus>
            
         </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="合计(人)">
            <el-input type="number" v-model.number="total2" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="单位地址" prop="unitAddress">
            <el-input v-model="saveData.unitAddress" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="主要产品" prop="mainProducts">
            <el-input v-model="saveData.mainProducts" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
          <el-form-item label="年利润额（万元）" prop="annualProfit">
            <el-input type="number" v-model.number="saveData.annualProfit" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位能耗增加值（万元/吨标煤）" prop="valueAdded">
            <el-input type="number" v-model="saveData.valueAdded" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单位排放增加值（万元/吨）" prop="dischargeUnitEmission">
            <el-input type="number" v-model="saveData.dischargeUnitEmission" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="研发经费支持占营业收入比例（%）" prop="devBusRatio">
            <el-input type="number" v-model="saveData.devBusRatio" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col> -->

         <el-col :span="12">
          <el-form-item label="发明专利个数(个)" prop="inventionPatentsNum">
            <el-input type="number" v-model="saveData.inventionPatentsNum" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
         <el-col :span="12">
          <el-form-item label="实用型专利数量(个)" prop="utilityModelPatent">
            <el-input type="number" v-model="saveData.utilityModelPatent" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>   
        <el-col :span="12">
          <el-form-item label="软件著作权数量(个)" prop="softwareCopyright">
            <el-input type="number" v-model="saveData.softwareCopyright" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="其他专利个数(个)" prop="patentsNum">
            <el-input type="number" v-model="saveData.patentsNum" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
       
       
       
        
        <!-- <el-col :span="12" class="inputList">
          <el-form-item label="销售收入（万元）" prop="totalTaxAndSalesRevenue" :rules="{ required: true, validator: validatoIncome, trigger: 'blur' }">
            <div v-for="(item, index) in saveData.totalTaxAndSalesRevenue" :key="index">
              <el-input type="number" v-model="item.totalTax" placeholder="请输入" :maxlength="20" class="input1" :disabled="true">
                <template slot="prepend">{{ item.year }}年</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="inputList">
          <el-form-item label="税收总额（万元）" prop="totalTaxAndSalesRevenue" :rules="{ required: true, validator: validatoTotalRevenue, trigger: 'blur' }">
            <div v-for="(item, index) in saveData.totalTaxAndSalesRevenue" :key="index">
              <el-input type="number" v-model="item.salesRevenue" placeholder="请输入" :maxlength="20" class="input1" :disabled="true">
                <template slot="prepend">{{ item.year }}年</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col> -->
        
        <el-col>
          <el-form-item label="企业优势特点" prop="enAdvantage">
            <el-input type="textarea" :rows="8" v-model="saveData.enAdvantage" placeholder="请输入企业优势特点" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">入园申请表</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="applicationForm"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业营业执照复印件</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="license"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">法人身份证复印件</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="legalPersonCard"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">可行性研究报告</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="feasibilityStudyReport"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业近三年资产负债表</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="balanceSheet"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业近三年现金流量表</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="cashFlowStatement"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
    <div style="height: 16px; background: #f5f5f5"></div>
    <div class="fileBox">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业近三年利润表</div>
      </div>
      <div class="file">
        选择文件：
        <file-upload
          :file-type="[]"
          :files="incomeStatement"
          :limit="99"
          multiple
          :size-limit="20"
          size-unit="M"
          :disabled="disabled"
          :isImg="false"
          tip="文件大小不能超过20M"
          style="margin-left: 10px"
        ></file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from '@/components/FileUpload';
import { ProjectSmallStep } from '@/common/enum';
/** 可编辑的审核步骤 */
const editableSteps = [
 ProjectSmallStep.WAIT_EXAMINE,
 ProjectSmallStep.EXAMINE_REJECT,
 ProjectSmallStep.WAIT_SECOND_EXAMINE,
 ProjectSmallStep.REVIEW_REJECT,
 ProjectSmallStep.WAIT_SECOND_REVIEW
]
/**
 * 行业列表
 */
const industryList = [
'智慧安防','智能工业安全防控产品','车辆专用安全装备','新型安全材料','信息安全','安全服务等安全产业产品及其应用技术研发','医疗健康',
'装备制造','产品检测','软件服务','数字经济','海洋大数据','智能制造','车辆零部件研发与制造'
]

const debugFillData ={
        industry: '智慧安防',
        nameOfEnterprise: '开发调试企业',
        registeredCapital: '100000',
        legalPerson: '王钢蛋',
        projectContact: '王多鱼',
        contactWay: '微信',
        builtUpArea: '123',
        // areaCovered: '',
        have: '100',
        lease: '200',
        // total: '',
        // existEmployeesNum: '',
        managersNum: '100',
        artisanNum: '100',
        productionPersonnelNum: '1000',
        unitAddress: '浙江省杭州市余杭区',
        mainProducts: '印度神油',
        // exportProportion: '',
        // exportRegions: '',
        annualProfit: '100',
        valueAdded: '100',
        dischargeUnitEmission: '100',
        devBusRatio: '50',
        patentsNum: '100',
        inventionPatentsNum: '100',
        totalTaxAndSalesRevenue: [{ year: '2019' }, { year: '2018' }, { year: '2017' }],
        enAdvantage: '巨踏马牛批',
      }


import { mapState } from 'vuex';
import ElInputPlus from '@/components/el-input-plus.vue';
export default {
  name: 'basicInfo',
   watch:{
    saveData:{
      deep:true,
      handler(nv){
        localStorage.setItem("EDITING_FLAG",1)
      }
    }
  },
  data() {
    return {
      industryList,
      saveData: {
        industry: '',
        nameOfEnterprise: '',
        registeredCapital: '',
        legalPerson: '',
        projectContact: '',
        contactWay: '',
        builtUpArea: '',
        // areaCovered: '',
        have: '',
        lease: '',
        // total: '',
        // existEmployeesNum: '',
        managersNum: '',
        artisanNum: '',
        productionPersonnelNum: '',
        unitAddress: '',
        mainProducts: '',
        // exportProportion: '',
        // exportRegions: '',
        annualProfit: '',
        valueAdded: '',
        dischargeUnitEmission: '',
        devBusRatio: '',
        patentsNum: '',
        inventionPatentsNum: '',
        utilityModelPatent: '',
        softwareCopyright: '',
        totalTaxAndSalesRevenue: [{ year: '2019' }, { year: '2018' }, { year: '2017' }],
        enAdvantage: '',
      },
      saveRules: {
        nameOfEnterprise: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        industry: [{ required: true, message: '请输入所属行业', trigger: 'blur' }],
        registeredCapital: [{ required: true, message: '请输入注册资金', trigger: 'blur' }],
        legalPerson: [{ required: true, message: '请输入法定代表人', trigger: 'blur' }],
        projectContact: [{ required: true, message: '请输入项目联系人', trigger: 'blur' }],
        contactWay: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        builtUpArea: [{ required: true, message: '请输入建筑面积', trigger: 'blur' }],
        // areaCovered: [{ required: true, message: '请输入占地面积', trigger: 'blur' }],
        have: [{ required: true, message: '请输入自有', trigger: 'blur' }],
        lease: [{ required: true, message: '请输入租赁', trigger: 'blur' }],
        // total: [{ required: true, message: '请输入合计', trigger: 'blur' }],
        // existEmployeesNum: [{ required: true, message: '请输入现有员工人数 ', trigger: 'blur' }],
        managersNum: [{ required: true, message: '请输入管理人员人数', trigger: 'blur' }],
        artisanNum: [{ required: true, message: '请输入研发技术人员人数', trigger: 'blur' }],
        productionPersonnelNum: [{ required: true, message: '请输入生产人员人数', trigger: 'blur' }],
        unitAddress: [{ required: true, message: '请输入单位地址', trigger: 'blur' }],
        mainProducts: [{ required: true, message: '请输入主要产品', trigger: 'blur' }],
        // exportProportion: [{ required: true, message: '请输入外销比例', trigger: 'blur' }],
        // exportRegions: [{ required: true, message: '请输入外销地区', trigger: 'blur' }],
        annualProfit: [{ required: true, message: '请输入年利润额', trigger: 'blur' }],
        // valueAdded: [{ required: true, message: '请输入单位能耗增加值', trigger: 'blur' }],
        // dischargeUnitEmission: [{ required: true, message: '请输入单位排放增加值', trigger: 'blur' }],
        // devBusRatio: [{ required: true, message: '请输入研发经费支持占营业收入比例', trigger: 'blur' }],
        patentsNum: [{ required: true, message: '请输入专利个数', trigger: 'blur' }],
        inventionPatentsNum: [{ required: true, message: '请输入发明专利个数', trigger: 'blur' }],
        utilityModelPatent: [{ required: true, message: '实用新型专利数量', trigger: 'blur' }],
        softwareCopyright: [{ required: true, message: '软件著作权数量', trigger: 'blur' }],
        // enAdvantage: [{ required: true, message: '请输入企业优势特点', trigger: 'blur' }],
      },
      applicationForm: [],
      license: [],
      legalPersonCard: [],
      feasibilityStudyReport: [],
      balanceSheet: [],
      cashFlowStatement: [],
      incomeStatement: [],
    };
  },
  components: {
    fileUpload,ElInputPlus
  },
  computed: {
    disabled() {
      /** @type { ProjectSmallStep } 当前步骤*/
      const currentStep = this.saveData.projectSmallStep
      const disabled = !editableSteps.includes(currentStep)&& !!this.saveData.id
      console.log("diabled 判断",{currentStep,disabled,editableSteps})

      // let disabled = this.saveData.projectStatus >= 2 ? true : false;
      this.$store.commit('updateValue', { formDisabled: disabled });
      return disabled;
    },
    total1() {
      return Math.round(Number(this.saveData.have) + Number(this.saveData.lease)) || 0;
    },
    total2() {
      let result =  Number(this.saveData.managersNum) + Number(this.saveData.artisanNum) + Number(this.saveData.productionPersonnelNum) || 0;
      return Math.round(result)
    },
    projectId(){
      return this.$store.state.projectId || this.$route.params.id
    }
  },
  methods: {
    init() {
      console.log("SafeBasicInfo初始化",this.projectId, this.$route.params)
      if (this.projectId ) {
        this.$api.getSafeProjectBasic(this.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            this.saveData = data.safeProjectBasic || {};
            this.applicationForm = data.applicationForm;
            this.license = data.license;
            this.feasibilityStudyReport = data.feasibilityStudyReport;
            this.balanceSheet = data.balanceSheet;
            this.cashFlowStatement = data.cashFlowStatement;

            this.legalPersonCard = data.legalPersonCard;
            this.incomeStatement = data.incomeStatement;
            console.log(`获取到数据`,data)
            this.$set(
              this.saveData,
              'totalTaxAndSalesRevenue',
              data.totalTaxAndSalesRevenue.length > 0 ? data.totalTaxAndSalesRevenue : [{ year: '2019' }, { year: '2018' }, { year: '2017' }]
            );
          }
        });
      }
    },
    validatoData() {
      let data;
      this.$refs['saveForm'].validate((valid,obj) => {
         if(!valid) this.$message.error(obj[ Object.keys(obj)[0]][0].message)
        data = valid;
      });
      if (!data) {
        return false;
      }
      if (this.applicationForm.length < 1) {
        this.$message.error({ message: `请上传 入园申请表`, offset: 80 });
        return false;
      }
      if (this.license.length < 1) {
        this.$message.error({ message: `请上传 企业营业执照复印件`, offset: 80 });
        return false;
      }
      if (this.legalPersonCard.length < 1) {
        this.$message.error({ message: `请上传 法人身份证复印件`, offset: 80 });
        return false;
      }
      if (this.feasibilityStudyReport.length < 1) {
        this.$message.error({ message: `请上传 可行性研究报告`, offset: 80 });
        return false;
      }
      if (this.balanceSheet.length < 1) {
        this.$message.error({ message: `请上传 企业近三年资产负债表`, offset: 80 });
        return false;
      }
      if (this.cashFlowStatement.length < 1) {
        this.$message.error({ message: `请上传 企业近三年现金流量表`, offset: 80 });
        return false;
      }
      if (this.incomeStatement.length < 1) {
        this.$message.error({ message: `请上传 企业近三年利润表`, offset: 80 });
        return false;
      }

      return true;
    },
    validatoIncome(rule, value, callback) {
      value.forEach((v) => {
        if (v.totalTax === '' || v.totalTax === undefined) {
          callback(new Error(`请输入${v.year}销售收入`));
          return;
        }
      });
      callback();
    },
    validatoTotalRevenue(rule, value, callback) {
      value.forEach((v) => {
        if (v.salesRevenue === '' || v.salesRevenue === undefined) {
          callback(new Error(`请输入${v.year}年税收总额`));
          return;
        }
      });
      callback();
    },
  },
};
</script>

<style lang='scss' scoped>
/deep/ .el-input-group__prepend {
  line-height: 38px;
  text-align: center;
  width: 85px;
  background-color: #f5f5f5;
}
.inputList {
  /deep/.el-input-group--prepend {
    display: flex !important;
    .el-input__inner:focus {
      border-color: #409eff !important;
    }
  }
  .input1 {
    /deep/.el-input-group__prepend {
      border-radius: 4px 0 0 0;
    }
    /deep/.el-input__inner {
      border-left: 1px solid transparent;
    }
  }
  .input2 {
    /deep/.el-input-group__prepend {
      border: unset;
      border-left: 1px solid #dddddd;
      border-radius: 0;
    }
    /deep/.el-input__inner {
      border: 1px solid transparent;
      border-right: 1px solid #dddddd;
    }
  }
  /deep/.el-form-item.is-error {
    .el-input__inner {
      border-color: #f56c6c;
    }
  }
  .input3 {
    /deep/.el-input-group__prepend {
      border-radius: 0 0 0 4px;
    }
    /deep/.el-input__inner {
      border-left: 1px solid transparent;
    }
  }
}
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
