<template>
  <div style="padding: 0 20px 20px">
    <div class="title">
      <div class="line"></div>
      <div class="text">人员安排</div>
    </div>
    <el-button type="primary" @click="add(table, info)" style=" margin-bottom: 20px" :disabled="disabled">新增</el-button>
    <el-table :data="table" style="width: 100%">
      <el-table-column label="数量">
        <template slot-scope="scope">
          <el-input type="number" v-model="scope.row.num" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="解决办法">
        <template slot-scope="scope">
          <el-input v-model="scope.row.solution" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="平均工资水平">
        <template slot-scope="scope">
          <el-input type="number" v-model="scope.row.wage" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template slot-scope="scope">
          <el-select v-model="scope.row.type" placeholder="请选择">
            <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value" :disabled="disabled"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <el-button type="danger" plain @click="deletes(scope.$index, table,scope.row)" v-if="scope.$index != 0" :disabled="disabled">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'staffing',
  data() {
    return {
      table: [
        {
          num: '',
          solution: '',
          wage: '',
          type: '',
        },
      ],
      type: [
        { value: '1', label: '管理人员' },
        { value: '2', label: '技术人员' },
        { value: '3', label: '生产工人' },
        { value: '4', label: '职工人数' },
      ],
      info: {
        num: '',
        solution: '',
        wage: '',
        type: '',
      },
    };
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getSafeProjectPersonArrange(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            this.table = data.personArrangeList.length > 0 ? data.personArrangeList : this.table;
          }
        });
      }
    },
    add(table, obj) {
      table.push(Object.assign({}, obj));
    },
      async deletes(index, table,row,model) {
      console.log(`删除`,{index,table,row})
      if(row.id){
         await this.$confirm("确认删除?","删除",{ type:'warning' })
         await this.$api.deleteSafePersonArrange(row.id);
         this.$message.success("删除成功")
      }
     

      table.splice(index, 1);
    },
    validatoData() {
      let flage;
      this.table.forEach((e) => {
        for (const key in this.info) {
          if (!e[key] && e[key] !== 0&& e[key] !== '0') {
            this.$message.error({ message: `请将人员安排填写完整`, offset: 80 });
            flage = true;
            return;
          }
        }
      });
      if (flage) return false;
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
