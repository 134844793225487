<template>
  <div style="padding: 0 20px 20px">
    <div class="title">
      <div class="line"></div>
      <div class="text">前后三年预估经营数据</div>
    </div>
    <!-- <el-button type="primary" @click="add(table, info)" style=" margin-bottom: 20px" :disabled="disabled">新增</el-button> -->
    <el-table :data="table" style="width: 100%">
      <el-table-column label="年份" prop="year" width="100"></el-table-column>
      <el-table-column label="主营收入">
        <template slot-scope="scope" >
          <el-input type="number" v-model.number="scope.row.mainIncome" @change="handleComputeTable(scope)" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="科技收入">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.numberScientificResearchers"  @change="handleComputeTable(scope)"  placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
       <el-table-column label="科技收入占营收比例" width="150">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.proportionTechnologyRevenue" placeholder="" :maxlength="100" :disabled="true">
             <template v-slot:append>%</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="研发经费">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.rdFunds"  @change="handleComputeTable(scope)" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
        </template>
      </el-table-column> 
       <el-table-column label="研发经费占营收比例" width="150">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.proportionFundsRevenue" placeholder="" :maxlength="100" :disabled="true">
             <template v-slot:append>%</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="年利润额">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.annualProfit" append  @change="handleComputeTable(scope)" placeholder="请输入" :maxlength="100" :disabled="disabled">
          
          </el-input>
        </template>
      </el-table-column>
       <el-table-column label="利润率" width="150">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.profitMargin" placeholder="" :maxlength="100" :disabled="true">
              <template v-slot:append>%</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="税收">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.taxRevenue" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="亩均税收">
        <template slot-scope="scope">
          <el-input  type="number" v-model.number="scope.row.perMuTax" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="员工总数">
        <template slot-scope="scope">
          <el-input-plus type="integer" v-model="scope.row.totalNumberEmployees" :disabled="disabled"></el-input-plus>
          <!-- <el-input type="number" v-model.number="scope.row.totalNumberEmployees" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input> -->
        </template>
      </el-table-column>
      <el-table-column label="科研人数">
        <template slot-scope="scope">
          <el-input-plus type="integer" v-model="scope.row.numberScientificResearchers" :disabled="disabled"></el-input-plus>
          <!-- <el-input v-model.number="scope.row.numberScientificResearchers" placeholder="请输入" :maxlength="100" :disabled="disabled"></el-input> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from "dayjs"
import ElInputPlus from '@/components/el-input-plus.vue';
/**
 *生成年份列表
 *@param  {number} direction 方向  1 往后 -1 往前
 *@param  {number} offset 偏移量 跳过 ```offset``` 年
 */
const generateYearsList =  (direction=1,offset = 0)=>Array.from({length:3}).map((_,index)=>({year:dayjs().add((index + offset )* direction,'y').format("YYYY")}))
export default {
  name: 'staffing',
  components:{ElInputPlus},
  data() {
    return {
      table: [
          
      ],
      type: [
        { value: '1', label: '管理人员' },
        { value: '2', label: '技术人员' },
        { value: '3', label: '生产工人' },
        { value: '4', label: '职工人数' },
      ],
      info: {
        num: '',
        solution: '',
        wage: '',
        type: '',
      },
    };
  },
  watch:{
    table:{
      handler(nv){
      },
      deep:true
    }
    
  },
  mounted() {
    // const nextThreeYears =generateYearsList(1);
    // const lastThreeYears= generateYearsList(-1,1)
    // this.table.push(...lastThreeYears,...nextThreeYears)
    // console.log("生成table",this.table)
    // this.initTable()
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
      projectId:state=>state.projectId,
    }),
    // tableColumns(){
    //   const curYear = new Date().getFullYear();//今年
    //   return []
    // }
  },
  methods: {
    initTable(){
       const baseData = {projectId:this.projectId,year:"",salesRevenue:"",taxRevenue:"",profit:"",permanentPersonnel:""}
       let curYear = new Date().getFullYear();
       return new Array(4).fill().map((item,index)=>{
         return {...baseData,year:`${curYear+index}年`}
       })
    },
    handleComputeTable(scope){
      const {row} = scope
       /** 科技收入占营收比例（科技/营收*100）*/
      scope.row.proportionTechnologyRevenue  =(row.numberScientificResearchers / row.mainIncome * 100 ).toFixed(2)
      scope.row.proportionTechnologyRevenue = isNaN(scope.row.proportionTechnologyRevenue) ? 0 : scope.row.proportionTechnologyRevenue
       /** 研发经费占营收比例（研发/营收*100）*/
      scope.row.proportionFundsRevenue  = (row.rdFunds / row.mainIncome * 100).toFixed(2) 
      scope.row.proportionFundsRevenue = isNaN(scope.row.proportionFundsRevenue) ? 0 : scope.row.proportionFundsRevenue

       /** 利润率（利润/营收*100）*/
      scope.row.profitMargin  = (row.annualProfit / row.mainIncome * 100).toFixed(2) 
      scope.row.profitMargin = isNaN(scope.row.profitMargin) ? 0 : scope.row.profitMargin

      return row;
      console.log(`表格计算`,scope)
    },
    async init() {
      console.log("调用了初始化",this.projectId)
      if (this.$store.state.projectId != null) {
        let {result,success} = await this.$api.getEditSafeProjectLastAndNextThreeYears({projectId:this.projectId});
        console.log(`表格数据获取`,result)
        result =  result.map(item=>{
          const {proportionTechnologyRevenue,proportionFundsRevenue,profitMargin} = item;
          console.log(`数值检查`,{proportionTechnologyRevenue,proportionFundsRevenue,profitMargin})
          if(!item.proportionTechnologyRevenue || !item.proportionFundsRevenue || !item.profitMargin){
            return this.handleComputeTable({row:item})
          }
          return item;
        })
        if(success){
          this.table = result.length  ? result : this.initTable()
        }
      }else{
        this.table = this.initTable()
      }
    },
    add(table, obj) {
      table.push(Object.assign({}, obj));
    },
    deletes(i, table) {
      table.splice(i, 1);
    },
    validatoData() {
      let flage;
      this.table.forEach((e) => {
        for (const key in this.info) {
          if (!e[key] && e[key] !== 0&& e[key] !== '0') {
            this.$message.error({ message: `请将人员安排填写完整`, offset: 80 });
            flage = true;
            return;
          }
        }
      });
      if (flage) return false;
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
