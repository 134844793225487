<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterprisesInPark' }">园区入驻</el-breadcrumb-item>
      <el-breadcrumb-item>入园审核新增</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content table-box table-no-border">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true"  :before-leave="handleBeforeTabLeave" >
        <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in list" :key="index">
          <span slot="label" :class="{
            disabled:!projectId && index,


          }"><i style="color:red;" v-if="item.required">*</i>{{item.label}}</span>
          <component :is="item.name" :ref="item.name"></component> 
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-button type="primary" @click="save()" :loading="loading" v-if="!disabled" style="margin: 20px 12px 30px 24px">保存</el-button>
    <el-button @click="$router.go(-1)" style=" margin: 20px 12px 30px 24px">{{ disabled ? '返回' : '取消' }}</el-button>
  </div>
</template>
<script>
import basicInfo from '../components/safeBasicInfo';
import situation from '../components/situation';
import economicAnalysis from '../components/economicAnalysis';
import future from '../components/future';
import energy from '../components/energy';
import staffing from '../components/staffing';
import dayjs from "dayjs"
window.dayjs = dayjs
import { mapState } from 'vuex';
export default {
  data() {
    return {
      activeName: 'basicInfo',
      replaceFlag:false,
      loading: false,
      list: [
        { label: '企业基础信息', name: 'basicInfo' ,required:true},
        { label: '拟投资项目情况', name: 'situation' ,required:true},
        { label: '前后三年经营数据', name: 'future' ,required:true },
        { label: '经济效益分析', name: 'economicAnalysis'},
        { label: '人员安排', name: 'staffing' },
        { label: '能源需求', name: 'energy' },
      ],
      id: null,
    };
  },
  watch: {
    activeName(nv){
      console.log('activeNameChange',nv)
        const path = this.$route.path
        this.$router.replace({path,query:{form:nv}})
    },
    id:{
      handler(nv){
        console.log("projectId 更新",nv)
        this.$store.commit('updateValue', { projectId: nv });
        
        // console.log(`已调用[${this.activeName}[0]]的初始化`, this.$refs[this.activeName][0].init)
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
     projectId () {
      const id = this.$route.params.id || this.$store.state.projectId;
       console.log("ProjectId获取",id)
      return id
    }
  },
  created() {
    
    this.replaceFlag = false;
    this.getId()
  },
  beforeDestroy() {
    // 移除监听
    window.removeEventListener('beforeunload', this.out, true);
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    if (!this.disabled && !this.replaceFlag) {
      //新增时
      setTimeout(() => {
        // hash模式下，此处必须要加延迟执行，主要解决浏览器前进后退带来的闪现
        this.$confirm('系统可能不会保存您所做的更改。', '离开当前页面?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then((result) => {
            next();
          })
          .catch(() => {
            next(false);
          });
      }, 200);
    } else {
      next();
    }
  },
  mounted() {
    // console.log(this.projectId)
  
    const form = this.$route.query.form
    if(this.list.map(item=>item.name).includes(form)){
      this.activeName = form
    }
      console.log("mounted执行","调用",this.$refs[this.activeName][0],`的`,this.$refs[this.activeName][0].init)
     this.$refs[this.activeName][0].init();
    //当浏览器窗口关闭或者刷新时，会触发beforeunload事件。当前页面不会直接关闭，可以点击确定按钮关闭或刷新，也可以取消关闭或刷新。
    window.addEventListener('beforeunload', this.out, true);
  },
  methods: {
    async handleBeforeTabLeave(name){
      console.log("tabs切换拦截",name,this.projectId,this.id, this.$refs[name][0]);

      if(!this.projectId && !this.id){
        this.$message.warning("请先提交基础信息填写")
        throw("请先提交基础信息填写")    
      }else if(localStorage.getItem("EDITING_FLAG")){
          const confirm =  await this.$confirm('系统可能不会保存您所做的更改。', '离开当前表单?', { 
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        });
        if(confirm){
          localStorage.removeItem("EDITING_FLAG")
          this.$refs[name][0].init();
          return true
        }
      }
      this.$refs[name][0].init();
      return true
      
      
    },
    getId(){
      this.id = this.projectId;
      return this.projectId
    },
    out(e) {
      if (!this.disabled) {
        //新增时
        if (/Apple/.test(navigator.vendor)) {
          e.preventDefault();
        } else {
          e.returnValue = 'a';
        }
      } else {
        e.preventDefault();
      }
    },
  
    handleClick(tab, event) {
    },
    async save() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      if (this.activeName == 'basicInfo') {
        if (!this.$refs.basicInfo[0].validatoData()) {
          return;
        }

        let params = JSON.parse(JSON.stringify(this.$refs.basicInfo[0].saveData));
        params.applicationForm = JSON.stringify(this.$refs.basicInfo[0].applicationForm);
        params.balanceSheet = JSON.stringify(this.$refs.basicInfo[0].balanceSheet);
        params.license = JSON.stringify(this.$refs.basicInfo[0].license);
        params.legalPersonCard = JSON.stringify(this.$refs.basicInfo[0].legalPersonCard);
        params.feasibilityStudyReport = JSON.stringify(this.$refs.basicInfo[0].feasibilityStudyReport);
        params.cashFlowStatement = JSON.stringify(this.$refs.basicInfo[0].cashFlowStatement);
        params.incomeStatement = JSON.stringify(this.$refs.basicInfo[0].incomeStatement);
        params.totalTaxAndSalesRevenue = JSON.stringify(params.totalTaxAndSalesRevenue);
    
        params.projectId = this.id;

        
        this.$api.editSafeProjectBasic(params).then((res) => {
          console.log("保存成功1111",res,this.id)
  
          if(!this.id){//新增情况-
            console.log(`新增成功刷新页面`,`${window.location.href}/${res.result}`)
            window.location.replace(`${window.location.href}/${res.result}`)
          }
          this.id = res.result
          this.$message.success({ message: `保存成功`, offset: 80 });
          // this.getId();
        });
      } else if (!this.id) {
        this.$message.error({ message: `请先保存企业基础信息`, offset: 80 });
        return;
      }
      if (this.activeName == 'situation') {
        if (!await this.$refs.situation[0].validatoData()) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.$refs.situation[0].saveData));
        params.entrySigningTime = dayjs(params.entrySigningTime).format("YYYY-MM-DD")
        params.expectedOfficeTime = dayjs(params.expectedOfficeTime).format("YYYY-MM-DD")
        params.projectId = this.id;
        this.$api.editSafeProjectWillInvested(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'economicAnalysis') {
        if (!await this.$refs.economicAnalysis[0].validatoData()) {
          return;
        }
        let params = {};
        params.entity = JSON.stringify(this.$refs.economicAnalysis[0].table);
        params.projectId = this.id;
        this.$api.editSafeProjectBenefit(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'staffing') {
        if (!await this.$refs.staffing[0].validatoData()) {
          return;
        }
        let params = {};
        params.personArrangeListStr = JSON.stringify(this.$refs.staffing[0].table);
        params.projectId = this.id;
        this.$api.editSafeProjectPersonArrange(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'energy') {
        if (!await this.$refs.energy[0].validatoData()) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.$refs.energy[0].saveData));
        params.projectId = this.id;
        this.$api.editSafeProjectEnergyRequire(params).then((res) => {
          this.$message.success({ message: `保存成功`, offset: 80 });
          this.$refs[this.activeName][0].init();
        });
      }
      if (this.activeName == 'future') {
        let data= this.$refs.future[0].table;
        console.log("保存前后三年营收预估",data)
         let res = await this.$api.saveSafeProjectThreeYears(data);
        console.log("保存返回值",res)
         this.$message.success({ message: `保存成功`, offset: 80 });
         this.$refs[this.activeName][0].init();
        return;
      }
    },
  },
  components: {
    basicInfo,
    situation,
    economicAnalysis,
    staffing,
    energy,
    future
  },
};
</script>
<style lang='scss' scoped>
.disabled{
   color: rgb(170, 169, 169);
}
.box {
  .content {
    // padding: 20px;
    background-color: #fff;
    overflow: hidden;
    /deep/ .el-tabs__item.is-active {
      color: #135694;
    }
    /deep/ .el-tabs__item:hover {
      color: #135694;
    }
    /deep/.el-tabs__header {
      margin: 20px;
      margin-bottom: 15px;
    }
    /deep/ .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin: 20px 0 19px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    /deep/ .el-form--label-top .el-form-item__label {
      line-height: 18px;
    }
  }
}
</style>
