<template>
  <el-form ref="saveForm" :model="saveData" @submit.native.prevent label-width="85px" label-position="top" style="padding: 0 20px 10px">
    <div class="title">
      <div class="line"></div>
      <div class="text">能源要求</div>
    </div>
    <el-row :gutter="64">
      <el-col :span="12">
        <el-form-item label="电力装机容量" prop="installedPowerCapacity">
          <el-input type="number" :disabled="disabled" v-model.number="saveData.installedPowerCapacity" placeholder="请输入电力装机容量" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="电力年用电量（度）" prop="annualElectricityConsumption">
          <el-input type="number" :disabled="disabled" v-model.number="saveData.annualElectricityConsumption" placeholder="请输入电力年用电量" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="煤月用量（吨）" prop="monthlyCoalConsumption">
          <el-input type="number" :disabled="disabled" v-model.number="saveData.monthlyCoalConsumption" placeholder="请输入煤月用量" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="水月用量（m³）" prop="monthlyWaterConsumption">
          <el-input type="number" :disabled="disabled" v-model.number="saveData.monthlyWaterConsumption" placeholder="请输入煤月用量" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="油用月用量（吨）" prop="monthlyOilConsumption">
          <el-input type="number" :disabled="disabled" v-model.number="saveData.monthlyOilConsumption" placeholder="请输入油用月用量" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="其他" prop="other">
          <el-input :disabled="disabled" v-model="saveData.other" placeholder="请输入其他" :maxlength="100"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="综合能耗" prop="comprehensiveEnergyConsumption">
          <el-input :disabled="disabled" type="number"  v-model.number="saveData.comprehensiveEnergyConsumption" placeholder="请输入综合能耗" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="万元产值能耗" prop="energyPer10000Output">
          <el-input :disabled="disabled" type="number"  v-model.number="saveData.energyPer10000Output" placeholder="请输入万元产值能耗" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="企业建设期（XX 个月）" prop="enterpriseConstructDate">
          <el-input :disabled="disabled" v-model="saveData.enterpriseConstructDate" placeholder="请输入企业建设期（XX 个月）" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="调试期为 XX 月" prop="commissioningPeriodDate">
          <el-input :disabled="disabled" v-model="saveData.commissioningPeriodDate" placeholder="请输入调试期" :maxlength="20"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="预计投产日期" prop="expectedProductionDate">
          <el-date-picker :disabled="disabled" v-model="saveData.expectedProductionDate" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"> </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'energy',
  data() {
    return {
      saveData: {
        installedPowerCapacity: '',
        annualElectricityConsumption: '',
        monthlyCoalConsumption: '',
        monthlyWaterConsumption: '',
        monthlyOilConsumption: '',
        other: '',
        comprehensiveEnergyConsumption: '',
        energyPer10000Output: '',
        enterpriseConstructDate: '',
        commissioningPeriodDate: '',
        expectedProductionDate: '',
      },
      saveRules: {
        installedPowerCapacity: [{ required: true, message: '请输入电力装机容量', trigger: 'blur' }],
        annualElectricityConsumption: [{ required: true, message: '请输入电力年用电量', trigger: 'blur' }],
        monthlyWaterConsumption: [{ required: true, message: '请输入水月用量', trigger: 'blur' }],
        monthlyCoalConsumption: [{ required: true, message: '请输入煤月用量', trigger: 'blur' }],
        monthlyOilConsumption: [{ required: true, message: '请输入油用月用量', trigger: 'blur' }],
        other: [{ required: true, message: '请输入其他', trigger: 'blur' }],
        comprehensiveEnergyConsumption: [{ required: true, message: '请输入综合能耗', trigger: 'blur' }],
        energyPer10000Output: [{ required: true, message: '请输入万元产值能耗', trigger: 'blur' }],
        enterpriseConstructDate: [{ required: true, message: '请输入企业建设期', trigger: 'blur' }],
        commissioningPeriodDate: [{ required: true, message: '请输入调试期', trigger: 'blur' }],
        expectedProductionDate: [{ required: true, message: '请选择预计投产日期', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        this.$api.getSafeProjectEnergyRequire(this.$store.state.projectId).then((res) => {
          if (res.success) {
            this.saveData = res.result.safeProjectEnergyRequire || {};
          }
        });
      }
    },
    validatoData() {
      let flage;
      this.$refs['saveForm'].validate((valid,obj) => {
          if(!valid) this.$message.error(obj[ Object.keys(obj)[0]][0].message)
        flage = valid;
      });
      if (!flage) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
